import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/free_print";

export const RootFreePrint = (props) => (
  <>
    <Routes>
      {
        !props.noRedirect && (
          <Route path="*" element={<Navigate to="/admin" replace />} />
          )
        }
      {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
      <Route path="free-print/*" element={<AdminLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
    </Routes>
  </>
);
