import React from "react";
import avatar from "assets/img/avatars/Ellipse 107.svg";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { useTranslation } from "react-i18next";

const Banner = (props) => {
  const { t } = useTranslation('translation');
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {props?.name}
        </h4>
        <p className="text-base font-normal text-gray-600">
          {props?.status ? `${t("active")}` : `${t("Inactive")}`}
        </p>
      </div>

      {/* Post followers */}
      <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {props?.nPaper || 0}
          </p>
          <p className="text-sm font-normal text-gray-600">{t("recycledpaper")}</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {props?.orders || 0}
          </p>
          <p className="text-sm font-normal text-gray-600">{t("prints")}</p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
