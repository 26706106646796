import React from 'react';


const types = {
  authVerification: "AUTH_VERIFICATION",
  authLogin: "AUTH_LOGIN",
  authSave: "AUTH_SAVE",
  authLogout: "AUTH_LOGOUT",
  update: "UPDATE_USER",
  setLocation: "SET_LOCATION",
};


const AuthReducer = (state, action) => {
    switch (action.type) {
      case types.authLogout:
        return {
          ...state,
          user: {
            auth: false,
          },
          printerId: "",
          orders: [],
          pagesLeft: 10,          
        };

      case types.authSave:
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
        };
      case types.authLogin:
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
            auth: true,
          },
        };
      case types.authVerification:
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
        };
      case types.update:
        return {
          ...state,
          user: {
            auth: true,
            token: 'JasdlkoasDua!"212sa',
            ...action.payload,
          },
        };
      case types.setLocation:
        return {
          ...state,
          user: {
            ...state.user,
            location: action.payload,
          },
        };

      default:
        return state;
    }
};

export {AuthReducer, types};