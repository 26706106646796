import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function Phoneinput(props) {    

    const {
        label,
        id,
        placeholder,
        onChange,
        value,
      } = props;    

    return (
      <PhoneInput
        placeholder={placeholder || "+1 (123) 456-7890"}
        value={value}
        onChange={onChange}
        defaultCountry="CO"
        className='border border-gray-500 rounded-xl px-4'
      />
    )
  }

  export default Phoneinput;