import { BiSupport } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const FreeCard = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="relative flex w-44  justify-center rounded-[20px] bg-gradient-to-br from-[#868CFF] via-[#432CF3] to-brand-500 pb-2">
      <div className=" mt-2 flex h-fit flex-col items-center p-2">
        <BiSupport className="h-10 w-10  text-white" />

        <p className="mt-1 p-1 text-center text-sm text-white">
          {t("support_c")}
        </p>

        <a
          target="blank"
          className="text-medium mt-4 block rounded-full bg-gradient-to-b from-white/50 to-white/10 px-4  py-4 text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 "
          href="https://api.whatsapp.com/send/?phone=573057232113&text=Hola!!%20necesito%20ayuda%20con%20%20Free.PrintGo&type=phone_number&app_absent=0"
        >
          <p className="text-md font-bold text-white">{t("contact")}</p>
        </a>
      </div>
    </div>
  );
};

export default FreeCard;
