import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Tintico from "./Tintico";
import logowompi from "assets/img/logowompi.png";
const TinticoModal = ({ isOpen, setIsOpen, rules }) => {
  const { t, i18n } = useTranslation();
  return (
    <Transition show={isOpen}>
      {" "}
      {/* Use React.Fragment */}
      <Dialog
        onClose={() => {
          window.location.reload();
          setIsOpen(false);
        }}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-75 p-4 md:p-0"
      >
        <Transition.Root show={isOpen}>
          {" "}
          {/* Remove as={React.Fragment} */}
          {/* <Dialog.Overlay className="bg-transparent fixed inset-0" /> */}
          <div className="flex min-h-[60vh] w-full min-w-[80vw] flex-col items-center justify-center rounded-md bg-white p-10 text-center ">
            <div
              style={{
                width: "50%",
                height: 0,
                paddingBottom: "154px",
                position: "relative",
                borderRadius: "1.5rem",
              }}
            >
              <iframe
                src="https://giphy.com/embed/xSh2LZbCzcHwoWImmt"
                width="100%"
                height="100%"
                style={{ position: "absolute", borderRadius: "1.5rem" }}
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              ></iframe>
            </div>
            <a
              className="mt-5"
              href="https://linktr.ee/printgoecoprint"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="mt-5 text-lg text-purple-500">
                {t("like_printgo")}
              </span>
            </a>
            <a
              className="mb-4 text-xl text-purple-600  hover:text-purple-500 lg:text-2xl"
              href="https://linktr.ee/printgoecoprint"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("invite_printgo")}
            </a>
            <div className="flex flex-col lg:flex-row">
              <a
                href="https://checkout.wompi.co/l/VPOS_euZ2Gy"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center justify-center mr-4 max-lg:pb-4"
              >
                <span className="text-md mb-2 rounded-full bg-purple-500 px-6 py-3 text-white">
                  {t("invite_with_wompi")}
                </span>
                <img
                  src={logowompi}
                  alt="cards"
                  className="size-40 h-20 rounded-md shadow-lg"
                />
              </a>
              <style jsx>{`
                .pp-BQFMKSE29UAPW {
                  text-align: center;
                  border: none;
                  border-radius: 1.5rem;
                  min-width: 11.625rem;
                  padding: 0 2rem;
                  height: 2.625rem;
                  font-weight: bold;
                  background-color: #ffd140;
                  color: #000000;
                  font-family: "Helvetica Neue", Arial, sans-serif;
                  font-size: 1rem;
                  line-height: 1.25rem;
                  cursor: pointer;
                }
              `}</style>
              <form
                action="https://www.paypal.com/ncp/payment/BQFMKSE29UAPW"
                method="post"
                target="_top"
                style={{
                  display: "inline-grid",
                  justifyItems: "center",
                  alignContent: "start",
                  gap: "0.5rem",
                }}
              >
                <input
                  className="pp-BQFMKSE29UAPW"
                  type="submit"
                  value={t("invite_with_paypal")}
                />
                <img
                  src="https://www.paypalobjects.com/images/Debit_Credit_APM.svg"
                  alt="cards"
                />
                <section>
                  Con la tecnología de
                  <img
                    src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg"
                    alt="paypal"
                    style={{ height: "0.875rem", verticalAlign: "middle" }}
                  />
                </section>
              </form>
            </div>
            <button
              className="mt-5 rounded-full bg-blueSecondary px-4 py-2 text-lg text-white"
              onClick={() => {
                setIsOpen(false);
                window.location.reload();
              }}
            >
              {t("other")}
            </button>
          </div>
        </Transition.Root>
      </Dialog>
    </Transition>
  );
};

export default TinticoModal;
