
import React from "react";
import { useTranslation } from "react-i18next"; 

const languages = [
  { code: "en", lang: "English - United States" },
  { code: "es", lang: "Spanish - Colombia" },
];

function LanguageSelector() {
  const [codelng, setCodelng] = React.useState();
  const { i18n } = useTranslation();

  const handleChange = (e) => {
      setCodelng(e.target.value);
      i18n.changeLanguage(e.target.value)
  }
  return (
    
<form className="max-w-sm mx-auto">
  <label htmlFor="countries" className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white">{i18n.t("selectlng")}</label>
  
  <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 px-[20px] p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleChange}>
  <option value={i18n.language}>{i18n.t("chooselng")}</option>
    {languages.map((language) => (<option value={language.code}>{language.lang}</option>))}
  </select>
</form>


  );
}
export default LanguageSelector;
