
import { gedOrdersRequest, getOrdersByUserRequest, getOrdersByUserFreePrintRequest } from "api/orders";

export const getAllOrdes = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      gedOrdersRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getOrdersByUser = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getOrdersByUserRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getOrdersByUserFreePrint = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getOrdersByUserFreePrintRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};