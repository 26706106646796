/*eslint-disable*/
import { useTranslation } from "react-i18next";

import React from "react";
export default function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <div className="z-[5] mx-auto flex w-full max-w-screen-sm flex-col items-center justify-between px-[20px] pb-4 lg:mb-6 lg:max-w-[100%] lg:flex-row xl:mb-2 xl:w-[1310px] xl:pb-6">
      <p className="mb-6 text-center text-sm text-gray-600 md:text-base lg:mb-0">
        ©{1900 + new Date().getYear()} {t("rights")}.
      </p>
      <ul className="flex flex-wrap items-center sm:flex-nowrap">
        <li className="mr-12">
          <a
            target="blank"
            href="https://wa.me/573057232113"
            className="text-sm text-gray-600 hover:text-gray-600 md:text-base lg:text-white lg:hover:text-white"
          >
            {t("support")}
          </a>
        </li>
        <li className="mr-12">
          <a
            target="blank"
            href="https://printgo.app/"
            className="text-sm text-gray-600 hover:text-gray-600 md:text-base lg:text-white lg:hover:text-white"
          >
            {t("aboutus")}
          </a>
        </li>
        <li className="mr-12">
          <a
            target="blank"
            href="https://printgo.app/terms"
            className="text-sm text-gray-600 hover:text-gray-600 md:text-base lg:text-white lg:hover:text-white"
          >
            T&C
          </a>
        </li>
        <li className="mr-12">
          <a
            target="blank"
            href="https://printgo.app/faq"
            className="text-sm text-gray-600 hover:text-gray-600 md:text-base lg:text-white lg:hover:text-white"
          >
            FAQ
          </a>
        </li>
      </ul>
    </div>
  );
}
