import React from 'react';


const types = {
  getQueue: 'GET_QUEUE',
  getOrders: 'GET_ORDERS',
  selectOrder: 'SELECT_ORDER',
  setPrinterId: 'SET_PRINTER_ID',
  cleanSelected: 'CLEAN_SELECT',
  setPagesLeft: 'SET_PAGES_LEFT'
};


const PrintReducer = (state, action) => {
    switch (action.type) {
      case types.getQueue:
        return {
          ...state,
          queue: action.payload,
        };
      case types.getOrders:
        return {
          ...state,
          orders: action.payload,
        };
      case types.selectOrder:
        return {
          ...state,
          selectedOrder: action.payload,
        };
      case types.setPrinterId:
        return {
          ...state,
          printerId: action.payload,
        };
      case types.cleanSelected:
        return {
          ...state,
          selectedOrder: {},
        };
      case types.setPagesLeft:
      return {
        ...state,
        pagesLeft: action.payload,
      };

      default:
        return state;
    }
};

export {PrintReducer, types};