import React, {
	useCallback,
	useRef,
	useEffect,
	Fragment,
	useState,
	useImperativeHandle,
	forwardRef,
} from "react";
import { upload } from "@testing-library/user-event/dist/upload";
import { MdUploadFile } from "react-icons/md";
import upload1 from "assets/svg/uploadIcon.svg";
import "../uploadview/css/uploadview.css";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import { getAllOrdes } from "store/sagas/Orders";

import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import logo from "assets/img/printgo/logo_black.svg";
import { pdfjs } from "react-pdf";
import { AiOutlineCheck, AiOutlineDelete } from "react-icons/ai";
import { PrintSettings } from "./PrintSettings";
import Swal from "sweetalert2";
import Storage from "views/admin/profile/components/Storage";
import { StoreContext } from "store/StoreProvider";
import { types } from "store/Print";
import { useSearchParams } from "react-router-dom";
import { MyDialog } from "views/auth/components/modalLanguage2";
import AdModal from "views/auth/components/Ads_modal";
import RulesModal from "views/auth/components/rules_modal";
import Tintico from "components/shared/Tintico";
import TinticoModal from "components/shared/TinticoModal";
pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.js';

const ListItem = (props) => {
	const [isShowing, setIsShowing] = React.useState(false);
	const { t } = useTranslation();

	const handleRemoveFile = () => {
		props.removeFileList(
			props.file,
			props.indexkey,
			props.childRef.current.setPagesfunction,
			props.childRef.current.currentPagesleft,
		);

		// Actualizar el localStorage
	};

	return (
		<>
			<li
				onClick={(e) => {
					e.stopPropagation();
					if (props.preview !== props.file) {
						props.setPreview("");
						setTimeout(() => props.setPreview(props.file), 100);
					}
				}}
				className="max-w-full bg-[red]"
			>
				<div className="file-item flex justify-between border-b-2 bg-white px-2 py-4 hover:cursor-pointer hover:bg-gray-50">
					<div className="flex flex-col items-start justify-items-start max-w-[80%]">
						<div className="flex justify-items-start max-w-full">
							<h3 className="sm:text-md  text-sm font-medium text-ellipsis overflow-hidden max-w-full">
								{props.file.name}
							</h3>
						</div>
						<div className="justify-items-start">
							<span className="sm:text-md text-left text-sm font-medium text-gray-500 hover:text-brand-500">
								{t("pages")} {props.file.numpages}
							</span>
						</div>
					</div>
					<button
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleRemoveFile();
						}}
						onMouseEnter={() => setIsShowing(true)}
						onMouseLeave={() => setIsShowing(false)}
					>
						{!isShowing && (
							<AiOutlineCheck className="h-6 w-6 " color="#32D74B" />
						)}
						<Transition show={isShowing}>
							<AiOutlineDelete className="h-6 w-6 " color="#FF453A" />
						</Transition>
					</button>
				</div>
			</li>
		</>
	);
};

const Modal = (props) => {
	const [preview, setPreview] = React.useState(props.pdfFile[0] || "");
	const [files, setFiles] = React.useState(props.pdfFile || []);
	const [color, setColor] = React.useState("Grayscale");
	const cancelButtonRef = useRef(null);
	const borrarRef = useRef(null);
	const borrarRef2 = useRef(null);
	const [printSettingsState, setPrintSettingsState] = React.useState(null); // Estado para almacenar los cambios de PrintSettings

	const [store] = React.useContext(StoreContext);
	const [pagesLeft, setPagesLeft] = React.useState(store.pagesLeft);
	const [numPages, setNumPages] = React.useState(0);

	// Función para actualizar el estado desde PrintSettings
	const updatePrintSettingsState = (newState) => {
		setPrintSettingsState(newState);
	};

	const { t, i18n } = useTranslation();

	const removeFileList = (
		file,
		indexkey,
		setPagesfunction,
		currentPagesleft,
	) => {
		let newFiles = [...files];
		newFiles.splice(indexkey, 1);
		let currentPages = currentPagesleft();

		//setPagesfunction(5)
		// console.log("=====currentpages");
		// console.log(currentPages);
		if (!!newFiles.length) {
			setPagesfunction(file.numpages + currentPages);
			setPreview("");
			setTimeout(() => {
				setPreview(newFiles[0]);
			}, 100);
		} else {
			setPagesfunction(file.numpages + currentPages);
			setPreview("");
		}

		setFiles(newFiles);
	};

	React.useEffect(() => {
		setPreview(props.pdfFile[0]);
		console.log("=============props.pdfFile=======================");
		console.log(props.pdfFile);
		console.log("=========props.pdfFile===========================");
		setFiles(props.pdfFile);
	}, [props.pdfFile]);

	React.useEffect(() => {
		let localNumPages = 0;
		let localPagesLeft = store.pagesLeft;
		for (const file in files) {
			if (Object.hasOwnProperty.call(files, file)) {
				const element = files[file];
				localPagesLeft -= element.numpages;
				localNumPages += element.numpages;
			}
		}
		setPagesLeft(localPagesLeft);
		setNumPages(localNumPages);
	}, [files]);

	let colorMode = {
		Grayscale: "luminosity",
		Color: "initial",
	};

	let pdfContainer = {
		width: "100%",
		borderRadius: 10,
		marginLeft: 10,
		marginRight: 10,
		mixBlendMode: colorMode[color],
	};

	let totalpger = props.totalpages;

	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-50"
				initialFocus={cancelButtonRef}
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#F5F5F5]">
					<div className="flex min-h-full items-end justify-center  text-center sm:items-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative w-full transform overflow-hidden shadow-xl  transition-all">
								<div className="flex  items-end justify-center bg-[#F5F5F5] p-4 text-center sm:items-center sm:p-0">
									<div
										className="sm:flex-no-wrap flex min-w-full flex-col flex-wrap justify-between sm:flex-row"
										style={{ minHeight: "100vh" }}
									>
										<div className="mx-1   basis-1/5 sm:bg-white lg:max-w-[21%]">
											<div className="w-full logo-container flex justify-center border-none py-5 sm:border-b-2 sm:py-10">
												<img
													className="w-24 lg:w-40"
													src={logo}
													alt="PrintGo"
												/>
											</div>
											<div className="hidden sm:block">
												<div className="list-file-container">
													<div className="relative overflow-x-auto sm:rounded-lg">
														<table className="w-full table-auto border-separate rounded-lg text-left text-sm rtl:text-right dark:text-gray-400">
															<thead className="bg-gray-50 text-xs uppercase dark:bg-gray-700 dark:text-gray-400">
																<tr className="grid grid-cols-2 text-center  whitespace-nowrap text-sm">
																	<th className="py-3 px-2">
																		{t("queuefiles")}
																	</th>
																	<th className="py-3 px-2 ">
																		{t("pagesleft")}
																	</th>
																</tr>
															</thead>
															<tbody className="">
																<tr className=" grid grid-cols-2 text-center whitespace-nowrap text-sm ">
																	<td className="  ">{files.length}</td>
																	<td className=" ">{pagesLeft}</td>
																</tr>
															</tbody>
														</table>
													</div>
													{!!files.length && (
														<ul>
															{files.map((file, key) => (
																<ListItem
																	file={file}
																	preview={preview}
																	setPreview={setPreview}
																	setFiles={setFiles}
																	key={key}
																	indexkey={key}
																	removeFileList={removeFileList}
																	childRef={borrarRef2}
																/>
															))}
														</ul>
													)}
												</div>
												{pagesLeft > 0 && (
													<DropLite
														files={files}
														setFiles={setFiles}
														setPreview={setPreview}
														pages={props.pages}
														totalpages={props.totalpages}
														ref={borrarRef2}
														printSettingsState={printSettingsState}
													/>
												)}
											</div>
										</div>

										<div className="mx-1  my-4  min-h-20 w-full grow  basis-1/2 flex flex-col justify-center items-center  rounded-[20px] bg-white py-5 md:min-h-[95%] lg:max-w-[80%]">
											<div className="flex flex-col max-w-[80%]">
												<h3 className="pdf-name sm:text-md  text-sm ">
													{preview ? t("preview") : t("notpreview")}
												</h3>
												<h4 className="text-ellipsis overflow-hidden mb-2">
													{!!preview.name && `${preview.name.length > 20 ? preview.name.substring(0, 80) + '...' : preview.name} `}
												</h4>
											</div>
											<div className="preview-container flex flex-1 justify-center  w-full  ">
												{!!preview && (
													<embed
														style={pdfContainer}
														src={`${URL.createObjectURL(
															preview,
														)}#toolbar=0&scrollbar=0&navpanes=0`}
													/>
												)}
											</div>
										</div>

										<div className="mx-1 block basis-1/5 rounded-[20px] bg-white py-2 px-2 sm:hidden max-w-full">
											<div className="list-file-container">
												<div className="relative overflow-x-auto sm:rounded-lg">
													<table className="w-full table-auto border-separate rounded-lg text-left text-sm rtl:text-right dark:text-gray-400">
														<thead className="bg-gray-50 text-xs uppercase dark:bg-gray-700 dark:text-gray-400">
															<tr className="grid grid-cols-2 text-center  whitespace-nowrap text-sm">
																<th className="py-3 px-2">{t("queuefiles")}</th>
																<th className="py-3 px-2 ">{t("pagesleft")}</th>
															</tr>
														</thead>
														<tbody className="">
															<tr className=" grid grid-cols-2 text-center  whitespace-nowrap text-sm ">
																<td className="  ">{files.length}</td>
																<td className=" ">{pagesLeft}</td>
															</tr>
														</tbody>
													</table>
												</div>
												{!!files.length && (
													<ul>
														{files.map((file, key) => (
															<ListItem
																file={file}
																preview={preview}
																setPreview={setPreview}
																setFiles={setFiles}
																key={key}
																indexkey={key}
																removeFileList={removeFileList}
																childRef={borrarRef}
															/>
														))}
													</ul>
												)}
											</div>
											{pagesLeft > 0 && (
												<DropLite
													files={files}
													setFiles={setFiles}
													setPreview={setPreview}
													pages={props.pages}
													totalpages={props.totalpages}
													ref={borrarRef}
													printSettingsState={printSettingsState}
												/>
											)}
										</div>

										<div className="mx-1  my-4 basis-1/4 flex-col justify-center rounded-[20px] bg-white py-5">
											<PrintSettings
												cancelCallback={() => {
													props.setOpen(false)
													props.setOpenTintico(true)
												}}
												nfiles={files.length}
												setColor={setColor}
												files={files}
												pages={numPages}
												pagesLeft={pagesLeft}
												setPagesLeft={setPagesLeft}
												updatePrintSettingsState={updatePrintSettingsState}
											/>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

const PdfPreviewer = () => {
	const [open, setOpen] = React.useState(false);
	const [pdfFile, setPdfFile] = React.useState(null);
	const dropzoneRef = useRef(null);
	const buttonRef = useRef(null);
	const [store, dispatch] = React.useContext(StoreContext);
	const { t, i18n } = useTranslation();
	const [OpenRules, setOpenRules] = React.useState(false);
	const [OpenTintico, setOpenTintico] = React.useState(false);
	const [OpenModal, setOpenModal] = React.useState(false);
	const [pages, setPages] = React.useState(0);
	const [totalpages, setTotalpages] = React.useState(0);
	const [pagesleft, setPagesleft] = React.useState(0);
	const [localPagesLeft, setLocalPagesLeft] = useState(0);

	const [searchParams] = useSearchParams();

	React.useEffect(() => {
		if (searchParams.size > 0 && searchParams.get("printerid")) {
			console.log("pag restante 1 :", pagesleft);
			// console.log(
			//   '============searchParams.get("printerid")========================'
			// );
			// console.log(searchParams.get("printerid"));
			// console.log("====================================");
			// dispatch({
			//   type: types.setPrinterId,
			//   payload: searchParams.get("printerid"),
			// });
		}
	}, []);

	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_IP_BASE}/api-pwa/limit-orders-free-print`, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			redirect: "follow",
			referrerPolicy: "no-referrer",
			body: JSON.stringify({ id: store.user.id }),
		})
			.then((response) => response.json())
			.then((orders) => {
				let pagesleftvar = orders.pagesleft;
				setPagesleft(pagesleftvar);
				setLocalPagesLeft(pagesleftvar); // Actualizar el estado local
				dispatch({
					type: types.setPagesLeft,
					payload: pagesleftvar,
				});
			});
	}, []);

	const onDrop = useCallback((acceptedFiles, rejectedfile) => {
		console.log(rejectedfile.length);
		

		const errMessage = rejectedfile.length > 0 && rejectedfile[0].errors[0].message === "File is larger than 1048576 bytes" ? t("fileSizeError") : rejectedfile[0]?.errors ? rejectedfile[0].errors[0].message : "";
		if (!!rejectedfile.length) {
			Swal.fire(`${t("error")}`, `${errMessage}`, "warning");
		}

		if (!!acceptedFiles.length) {
			let totalpages = 0;
			let pagesleftvar = 0;

			var files = [];
			let fileCount = acceptedFiles.length;
			acceptedFiles.map((file, key) => {
				const reader = new FileReader();
				reader.onload = async () => {
					const buffer = reader.result;
					const loadingTask = pdfjs.getDocument(buffer);
					const pdf = await loadingTask.promise;
					file.numpages = pdf.numPages;
					totalpages += pdf.numPages;									

					files = [...files, acceptedFiles[key]];
					fileCount--;					

					if (totalpages > localPagesLeft && files.length === 1) {
						//Conditional when exceeding available sheets with a single file
						Swal.fire({
							title: `${file.name}`,
							text: `  sa ${t("exceeded")}.\n`,
							footer: ` ${t("textdocument")} ${totalpages} ${t("sheets")}`,
							icon: " warning",
						});
						return;
					}
					//condicional cuando paginas totales sea mayor a 5 y menor a 10, a la vez
					if (fileCount === 0) {
						if (totalpages % 2 !== 0 && totalpages > 5 && totalpages <= 10) {
							//validate if totalpages exceed available pages (STORE)
							if (totalpages > localPagesLeft) {
								console.log(
									"Excede las paginas disponibles en el dia: " +
										localPagesLeft
								);
								Swal.fire({
									title: `${file.name}`,
									text: `File must not exceed the limit of ${
										localPagesLeft === 0
											? "0"
											: hasDecimal(localPagesLeft / 2)
												? Math.floor(localPagesLeft / 2)
												: localPagesLeft / 2
									} sheets`,
									footer: ` ${t("textdocument")} ${totalpages} ${t(
										"pagestext"
									)}`,
									icon: "warning",
								});
								return;
							}
							setTotalpages(totalpages);
							setPages(totalpages);
							setPdfFile(files);
							setOpen(true);
							//
							return;
						} else if (
							totalpages % 2 === 0 &&
							totalpages > 5 &&
							totalpages <= 10
						) {
							if (totalpages > localPagesLeft) {
								console.log(
									"Excede las paginas disponibles en el dia: " +
										localPagesLeft
								);
								Swal.fire({
									title: `${file.name}`,
									text: `Excede tus hojas disponibles en el dia: ${
										localPagesLeft === 0
											? "0"
											: Math.trunc(localPagesLeft / 2)
									}`,
									footer: ` ${t("textdocument")} ${totalpages}${t(
										"pagestext"
									)}`,
									icon: "warning",
								});
								return;
							}

							setTotalpages(totalpages);
							setPages(totalpages);
							setPdfFile(files);
							setOpen(true);
							return;
						}
						if (totalpages > 10) {
							Swal.fire({
								title: `${file.name}`,
								text: `${t("pagesExceeded")}.\n`,
								footer: ` ${t("textdocument")} ${totalpages} ${t("pagestext")}`,
								icon: "warning",
							});
							return;
						}
						if (totalpages < 5)
							console.log("menos de 5 usan cantidad de hojas");
						let useSheets = totalpages * 2;
						setTotalpages(useSheets);
						setPages(totalpages);
						setPdfFile(files);
						setOpen(true);
						return;
					}
				};
				reader.readAsArrayBuffer(file);
			});
		}
	}, [localPagesLeft]); // Añadir localPagesLeft como dependencia
	

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		//onDropRejected,
		accept: {
			"application/pdf": [".pdf"],
		},
		maxSize: 1048576,
		maxFiles: 2,
	});

	function hasDecimal(numero) {
		return numero % 1 !== 0;
	}

	return (
    <div className="mt-5">
      <div {...getRootProps()} style={{}}>
        <input {...getInputProps()} />
        <div className="rounded-[20px] bg-white p-[20px]">
          <div className="border-sky-500 drop-container rounded-[13px] border-2 border-dashed bg-[#F5F5F5]">
            <div className="flex min-h-[40vh] min-w-[30vw] items-center justify-center ">
              <div className="flex flex-col items-center justify-center ">
                <img
                  className="my-5 h-[70px] w-[70px]"
                  alt="icon"
                  src={upload1}
                />
                <p className="title-dropdown-custom mb-1">
                  {isDragActive
                    ? "Suelta, lo estas apretando demasiado"
                    : t("presshereupload")}
                </p>
                <p className="my-5">{t("uploadpdf")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-5">
        <Storage />
      </div> */}
      {pdfFile && (
        <Modal
          open={open}
          setOpen={setOpen}
          pdfFile={pdfFile}
          pages={pages}
          totalpages={totalpages}
          pagesleft={pagesleft}
		  setOpenTintico={setOpenTintico}
        />
      )}
      {/* Buttons outside the dropzone div for opening modals */}
      <div className=" flex flex-col justify-center">
		<div className="flex flex-col lg:flex-row lg:justify-between lg:my-5 lg:mb-16 my-5 justify-center space-y-2 items-center">			
			<span className=" text-xl font-medium text-brand-500 hover:text-brand-500 dark:text-white">
			{t("available_sheets")} &nbsp;
			{localPagesLeft === 0
				? "0"
				: hasDecimal(localPagesLeft / 2)
				? Math.floor(localPagesLeft / 2)
				: localPagesLeft}
			</span>

			<button
			className=" text-2xl font-medium text-brand-500 hover:text-brand-400  dark:text-white"
			onClick={() => setOpenRules(true)} // Replace with your modal opening logic for Modal 1
			>
			{t("helpbutton")}
			</button>
		</div>
		<Tintico />
      </div>
      {OpenRules && (
        <RulesModal
          isOpen={OpenRules}
          setIsOpen={setOpenRules}
          adUrl="https://www.example.com"
          adContent="This is a great ad!"
        />
      )}

      {OpenTintico && (
        <TinticoModal
          isOpen={OpenTintico}
          setIsOpen={setOpenTintico}
        />
      )}
    </div>
  );
}

const DropLite = forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		console.log("paglef:", store.pagesLeft, "total", props.totalpages);
		if (props.pages != 0 && props.totalpages != 0) {
			setPagesleft(store.pagesLeft - props.totalpages);
		}
	}, [props.pages]);

	useEffect(() => {
		console.log();
		setFiles(props.files);
	}, [props.files]);

	React.useEffect(() => {
		setWhichSide(props.printSettingsState);
	}, [props.printSettingsState]);
	// console.log(props.files);
	const [oldfiles, setOldfiles] = useState(props.totalpages);
	const [pagesleftvar, setPagesleftvar] = useState(props.pages);
	const [pagesleft, setPagesleft] = useState(0);
	const [files, setFiles] = useState(props.files);
	const [acceptedPages, setAcceptedpages] = useState(0);
	const [store, dispatch] = React.useContext(StoreContext);
	const [whichSide, setWhichSide] = useState(props.printSettingsState);
	useEffect(() => {}, [pagesleft]);

	useImperativeHandle(ref, () => ({
		setPagesfunction(valor) {
			console.log(valor);
			setPagesleft(valor);
		},
		currentPagesleft() {
			return pagesleft;
		},
	}));

	const onDrop = async (acceptedFiles) => {
		//props.totalpages Si total pages es menor a 5 Usar hojas por separado a menos que lo cambie
		let acceptedPages = 0;
		let fileCount = acceptedFiles.length;
		acceptedFiles.map(async (file, key) => {
			const reader = new FileReader();
			reader.onload = async () => {
				const buffer = reader.result;
				const loadingTask = pdfjs.getDocument(buffer);
				const pdf = await loadingTask.promise;
				acceptedPages = acceptedPages + pdf.numPages;
				//primero contar todas las paginas y hacer
				let sheetsLeft = Math.ceil(pagesleft / 2);
				fileCount--;
				if (fileCount === 0) {
					if (acceptedPages > pagesleft && files.length === 1) {
						// texto modificado por juan
						Swal.fire(
							`${t("you")} ${t("have")} ${Math.ceil(pagesleft / 2)}   ${t(
								"impressions",
							)}`,
							t("filesexceed"),
							"error",
						);
						return;
					}
					if (Math.ceil(acceptedPages / 2) > sheetsLeft) {
						Swal.fire(
							`${t("you")} ${t("have")} ${Math.ceil(pagesleft / 2)}  ${t(
								"impressions",
							)}`,
							t("filesexceed"),
							"error",
						);
						console.log(
							"-----------------------------------------------------",
						);

						console.log(acceptedPages);
						return;
					}
					if (
						whichSide.sides === "one-sided" &&
						Math.floor(pagesleft / 2) < acceptedPages
					) {
						Swal.fire(t("pagesExceeded"), t("filesexceed"), "error");
						return;
					}
					file.numpages = pdf.numPages;
					const localleft = pagesleft - acceptedPages;
					setPagesleft(localleft);
					props.setFiles([...props.files, ...acceptedFiles]);
					props.setPreview("");
					setTimeout(() => props.setPreview(acceptedFiles[0]), 100);
				}
			};
			reader.readAsArrayBuffer(file);
		});
	};

	const onDropRejected = (acceptedFiles) => {
		Swal.fire(`${t("error")}`, `${t("rejectedfile")}`, "warning");
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		onDropRejected,
		accept: {
			"application/pdf": [".pdf"],
		},
	});

	return (
		<>
			{pagesleft > 0 && (
				<div className="mt-5">
					<div {...getRootProps()} style={dropzoneStyles}>
						<input {...getInputProps()} />

						<p>
							{isDragActive
								? "Suelta, lo estas apretando demasiado"
								: t("pressordrag")}
						</p>
					</div>
				</div>
			)}
		</>
	);
});

const dropzoneStyles = {
	border: "2px dashed #ccc",
	borderRadius: "4px",
	padding: "20px",
	textAlign: "center",
	cursor: "pointer",
};

const Uploadview = () => {
	return <PdfPreviewer />;
};

export { PdfPreviewer };
export default Uploadview;