import React, { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import scanqr from "assets/img/upload-file.gif";
import { useTranslation } from "react-i18next";
import interfaceScreen from "assets/img/interface.gif";
import live from "assets/img/live.gif";

const Step1 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className=" bg-white">
      <div className="flex justify-center align-center mb-4 max-w-44 ">
        <img
          src={scanqr}
          alt=""
          className="size-20 md:max-w-[50%]"
        />
      </div>
      <div className="flex flex-col text-2xl">
        <div className="mb-1 mt-2">
          <h2 className="text-3xl font-extrabold text-[#412947] tracking-wide">
            {t("step1head")}
          </h2>
        </div>
        <div className="items-end">
          <p className="text-[#412947] text-lg font-medium font-sans tracking-wide">
            {t("step1")}
          </p>
          {/* <p className="text-gray-200 text-lg font-medium font-sans tracking-wide">
            Load your PDF File in the web app, select your print options and press the "print" buttons.
            </p> */}
        </div>
      </div>
    </div>
  );
};
const Step2 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className=" bg-white">
      <div className="flex justify-center align-center mb-4">
        <img src={interfaceScreen} alt="" className="size-20 md:max-w-[50%]" />
      </div>
      <div className="flex flex-col text-2xl">
        <div className="mb-1 mt-2">
          <h2 className="text-3xl font-extrabold text-[#412947] tracking-wide">
            {t("step2head")}
          </h2>
        </div>
        <div className="items-end">
          <p className="text-[#412947] text-lg font-medium font-sans tracking-wide">
            {t("step2text")}
          </p>
          {/* <p className="text-gray-200 text-lg font-medium font-sans tracking-wide">
            Load your PDF File in the web app, select your print options and press the "print" buttons.
            </p> */}
        </div>
      </div>
    </div>
  );
};
const Step3 = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-white">
      <div className="flex justify-center align-center mb-4">
        <img src={live} alt="" className="size-20 md:max-w-[50%]" />
      </div>
      <div className="flex flex-col text-2xl">
        <div className="mb-1 mt-2">
          <h2 className="text-3xl font-extrabold text-[#412947] tracking-wide">
            {t("step3head")}
          </h2>
        </div>
        <div className="items-end">
          <p className="text-[#412947] text-lg font-medium font-sans tracking-wide">
            {t("step3text")}
          </p>
          {/* <p className="text-gray-200 text-lg font-medium font-sans tracking-wide">
            Load your PDF File in the web app, select your print options and press the "print" buttons.
            </p> */}
        </div>
      </div>
    </div>
  );
};

export const Carousel = ({ CloseBtn }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [moving, setMoving] = useState("right");
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(1);

  const handleClick = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const Reload = () => {
    window.location.reload();
  };

  const Case1 = () => {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      default:
        break;
    }
  };

  return (
    <div className="h-4/5 flex justify-center flex-col">
      <div className="">
        <Case1 />
      </div>
      <div className="pb-8">
        <a href="#" className={step === 1 ? "active circulo" : "circulo"} />
        <a href="#" className={step === 2 ? "active circulo" : "circulo"} />
        <a href="#" className={step === 3 ? "active circulo" : "circulo"} />
      </div>
      <div className="flex justify-between">
        <div className="w-2/5">
          {step > 1 && (
            <>
            <button
              type="button"
              onClick={handleBack}
              className="flex w-full justify-center rounded-lg bg-[#AC58F0] px-3 py-1.5 font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-lg tracking-wide px-1"
            >
              {t("back")}
            </button>
            </>
          )}
        </div>
        <div className="w-2/5">
          {step <= 3 && (
            <button
              type="button"
              onClick={step === 3 ? CloseBtn : handleClick}
              className="flex w-full justify-center rounded-lg bg-[#AC58F0] px-3 py-1.5 font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-lg tracking-wide px-1"
            >
              {step === 3 ? t("endsteps") : t("next")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
