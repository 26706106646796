import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "layouts/auth_free_print";
import { StoreContext } from "store/StoreProvider";

export const AuthFreePrint = (props) => {
  const [store] = React.useContext(StoreContext);

  return (
    <>
      <Routes>
        { !props.noRedirect && !store?.user?.auth && (
          <Route path="*" element={<Navigate to="/auth/free-print" />} />
        )}
        <Route path="auth/*" element={<AuthLayout />} />
      </Routes>
    </>
  );
};
