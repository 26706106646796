import React from "react";
import Banner from "./components/Banner";
import General from "./components/General";
import Project from "./components/Project";
import Upload from "./components/Upload";
import { Link, useParams } from "react-router-dom";
import { getUserFreePrint } from "store/sagas/Employes";
import { StoreContext } from "store/StoreProvider";

export const Profile = () => {
  const [user, setUser] = React.useState({});
  const [store] = React.useContext(StoreContext);


  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    getUserFreePrint({ id: store.user.id }).then((res) => setUser(res));
  };

  React.useEffect(() => {
    console.log(user);
  }, user);

  return (
    <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2  lg:grid-cols-2">
      <div className="w-ful mt-3 flex h-fit flex-col ">
        <div className="col-span-3 lg:!mb-0">
          <Banner {...user} />
        </div>
      </div>

      <div className="grid h-full">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          {!!Object.keys(user).length && <Project {...user} getUser={getUser} /> }
        </div>
      </div>
    </div>
  );
};
