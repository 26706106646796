import React from "react";
import { StoreContext } from "store/StoreProvider";
import { RootFreePrint } from "router/RootFreePrint";
import { AuthFreePrint } from "router/AuthFreePrint";
import { useSearchParams, useNavigate } from "react-router-dom";
import { types } from "store/Print";

const App = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  console.log(searchParams); // ▶ URLSearchParams {}

  React.useEffect(() => {
    if (searchParams.size > 0 && searchParams.get("printerid") && store.printerId !== searchParams.get("printerid")) {
      dispatch({
        type: types.setPrinterId,
        payload: searchParams.get("printerid"),
      });
      if (!store?.user.auth) {
        setTimeout(() => navigate("/auth/free-print"), 2000)
      }
    }
  }, [searchParams, store.printerId]);

  if (!store) return null;

  if (searchParams.size > 0) {

    if (!store?.user.auth) {
      return (
        <>
          <AuthFreePrint noRedirect={true} />
        </>
      );
    }

    return (
      <>
        {/* <h1>params: {searchParams.get("printerid")}</h1>
        <h1>id: {store.printerId}</h1>
        <button onClick={() => console.log(store.printerId)}>presioname</button> */}
        <RootFreePrint noRedirect={true} />
      </>
    );



    return <RootFreePrint noRedirect={true} />;

  } else {
    return (
      <>
        {!!store && !!store?.user && !!store?.user?.auth ? (
          <RootFreePrint />
        ) : (
          <AuthFreePrint />
        )}
      </>
    );
  }
};

export default App;
