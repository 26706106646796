import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import { Carousel } from 'views/admin/dashboard/components/Carousel';
import { useTranslation } from "react-i18next";


const RulesModal = ({ isOpen, setIsOpen, rules }) => {
  const { t, i18n } = useTranslation();
  return (
    <Transition show={isOpen}> {/* Use React.Fragment */}
      <Dialog onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-75 p-4 md:p-0">
        <Transition.Root show={isOpen}> {/* Remove as={React.Fragment} */}
          <Dialog.Overlay className="fixed inset-0 bg-transparent" />
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
          <div className="relative w-full h-full sm:w-full md:w-3/4 lg:w-1/2 mx-auto shadow-md rounded-3xl  p-8 bg-white">
            <div className="flex justify-between">
            <h2 className="text-2xl text-[#412947] font-bold pr-4">{t("howtoprint")}</h2>
              <button onClick={() => setIsOpen(false)} className="text-gray-400 hover:text-gray-600 focus:outline-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-4 text-center h-full ">
            <Carousel CloseBtn={() => setIsOpen(false)}/>
            </div>
          </div>
        </div>
          {/* <div className="fixed transform transition-all md:translate-y-0 -translate-y-1/2 sm:w-full md:w-3/4 lg:w-1/2 mx-auto shadow-md rounded-3xl bg-white p-8">
            <h2 className="text-xl font-medium mb-4">Rules and Terms</h2>
            <ul className="list-disc space-y-2">
              
            </ul>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="mt-4 px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
            >
              Close
            </button>
          </div> */}
        </Transition.Root>
      </Dialog>
    </Transition>
  );
};

export default RulesModal;