import React from "react";
import { useTranslation } from "react-i18next";
import logowompi from "assets/img/logowompi.png";
export default function Tintico() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center rounded-lg border border-gray-300 bg-gray-100 text-center lg:my-2 lg:p-2">
      <a
        className="mt-5 "
        href="https://linktr.ee/printgoecoprint"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="text-lg text-purple-500">{t("like_printgo")}</span>
      </a>
      <a
        className="mb-4 text-2xl  text-purple-600 hover:text-purple-500"
        href="https://linktr.ee/printgoecoprint"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("invite_printgo")}
      </a>
      <div className="flex flex-col lg:flex-row">
        {/* <div className="max-w-full overflow-x-auto p-4">
          <div id="paypal-container-BQFMKSE29UAPW" className="mt-2 max-w-full" />
        </div> */}

        <div className="mr-4 flex pb-4 lg:pb-0">
          <a
            href="https://checkout.wompi.co/l/VPOS_euZ2Gy"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center justify-center"
          >
            <span className="text-md text-white bg-purple-500 rounded-full px-6 py-3 mb-2">
              {t("invite_with_wompi")}
            </span>
            <img
              src={logowompi}
              alt="cards"
              className="size-40 h-20 rounded-md shadow-lg"
            />
          </a>
        </div>

        <style jsx>{`
          .pp-BQFMKSE29UAPW {
            text-align: center;
            border: none;
            border-radius: 1.5rem;
            min-width: 11.625rem;
            padding: 0 2rem;
            height: 2.625rem;
            font-weight: bold;
            background-color: #ffd140;
            color: #000000;
            font-family: "Helvetica Neue", Arial, sans-serif;
            font-size: 1rem;
            line-height: 1.25rem;
            cursor: pointer;
          }
        `}</style>
        <form
          action="https://www.paypal.com/ncp/payment/BQFMKSE29UAPW"
          method="post"
          target="_top"
          style={{
            display: "inline-grid",
            justifyItems: "center",
            alignContent: "start",
            gap: "0.5rem",
          }}
        >
          <input
            className="pp-BQFMKSE29UAPW"
            type="submit"
            value={t("invite_with_paypal")}
          />
          <img
            src="https://www.paypalobjects.com/images/Debit_Credit_APM.svg"
            alt="cards"
          />
          <section>
            Con la tecnología de
            <img
              src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg"
              alt="paypal"
              style={{ height: "0.875rem", verticalAlign: "middle" }}
            />
          </section>
        </form>
      </div>
    </div>
  );
}
